import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Footer from '../components/footer';
import Layout from '../components/layout';
import NavSection from '../components/nav-section';

class EventsSpecificPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  incrementQuantity = e => {
    e.preventDefault();
    this.setState({ quantity: parseInt(this.state.quantity) + 1 });
  };

  handleCounter = e => {
    let targetValue = e.target.value;
    let reg = new RegExp('^[0-9]*$');

    if (reg.test(targetValue) && targetValue > 0) {
      this.setState({ quantity: targetValue });
    } else {
      this.setState({ quantity: 0 });
    }
  };

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    const {
      // slug,
      featuredImage,
      infoSectionCategory,
      infoSectionTitle,
      infoSectionPlace,
      eventSpecificQuote,
      paragraphTitle1,
      paragraphLeftColumn,
      paragraphRightColumn,
      imageGallery1,
      imageGallery2,
      imageGallery3,
      imageGallery4,
      eventsYouMightLikeLeft,
      eventsYouMightLikeRight,
      guestSpeakers,
      eventDate,
    } = get(this, 'props.data.contentfulEventsSpecificDetails');
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };

    const guestsDiv = [];
    const eventDateTime = new Date(eventDate);
    const eventDateString = eventDateTime.toLocaleDateString(
      'en-US',
      optionsDate
    );
    const eventTimeString = eventDateTime.toLocaleTimeString(
      'en-US',
      optionsTime
    );

    // Guest Speakers for loop
    for (let i = 0; i < guestSpeakers.length; i++) {

      guestsDiv.push(
        <div key={i} className="col-md-6 text-center">
          <div className="row d-flex items-middle">
            <div className="col-md-4 mb20 text-left">
              <img
                width="150"
                src={guestSpeakers[i].guestSpeakerProfilePhoto.file.url}
                alt=""
              />
            </div>
            <div className="col-md-8 text-left">
              <h3 className="red speaker-name mb0">
                {guestSpeakers[i].speakerName}
              </h3>
              <p className="speaker-position mb0">
                {guestSpeakers[i].guestSpeakerCompany.guestSpeakerCompany}{' '}
                <br />
                {guestSpeakers[i].guestSpeakerPosition}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Layout>
        <div className="sm-transNav">
          <NavSection navMenus={navMenus} navTheme="nav-transparent" />
        </div>
        <Helmet title={`Events - ${infoSectionTitle}`} />

        <section id="SingleEventHeader" className="pt-5 pb-5">
          <div
            className="hero-section hero-banner special-slider events-header"
            style={{ backgroundImage: `url(${featuredImage.file.url})` }}
          >
            {/* <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-4 offset-md-4 text-center">
                  <h3 className="red font-tri mt40">
                    <div className="blue-green">{firstWordSub}</div>
                    {restSub}
                  </h3>
                  <h1 className="red font-tri mt20">
                    <div className="blue-green">{firstWordTitle}</div>
                    {restTitle}
                  </h1>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section id="SingleEventDetails" className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 d-flex items-middle">
                <div className="event-details">
                  <h3 className="event-type brown">{infoSectionCategory}</h3>
                  <h1 className="event-title red mt0 mb0">
                    {infoSectionTitle}
                  </h1>
                </div>
              </div>
              <div className="col-md-3 brown-border d-flex items-middle">
                <div className="middle">
                  <p className="font-gtaM mb0 ash">
                    <strong>
                      {eventDate}
                      {/* <span className="event-date text-uppercase">
                        {eventDateString}
                      </span>
                      <br />
                      <span className="event-time text-uppercase">
                        {eventTimeString}
                      </span> */}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="col-md-4 brown-border d-flex items-middle">
                <div className="middle">
                  <p className="font-gtaM mb0 ash info-location">
                    <strong>
                      <span className="event-place text-uppercase">
                        {infoSectionPlace.infoSectionPlace}
                      </span>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="EventInfo" className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-9">
                <h1 className="red text-left font-bigcas">
                  {eventSpecificQuote.eventSpecificQuote}
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="font-gtaM red mt60 mb30">{paragraphTitle1}</h2>
              </div>
              <div className="col-sm-12 col-md-6">
                {paragraphLeftColumn.paragraphLeftColumn}
              </div>
              <div className="col-sm-12 col-md-6 col-md-offset-1">
                {paragraphRightColumn.paragraphRightColumn}
              </div>
            </div>
          </div>
        </section>

        <hr className="hr-standard-events mt80" />

        <section id="GuestSpeakers" className="t-5 pb-5 mt60">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="font-tri red">Guest Speakers</h1>
              </div>
            </div>
            <div className="row speakers-section mb30">{guestsDiv}</div>
          </div>
        </section>

        <hr className="hr-standard-events" />

        <section id="EventsCTA" className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-4 offset-4">
                <div className="row">
                  <div className="col-md-6 text-center center mb60">
                    <a href="#">
                      <img width="90" src="/images/events-icon1.png" alt="" />
                      <p className="font-gtaM red text-uppercase mt50">
                        Add to Calendar
                      </p>
                    </a>
                  </div>
                  <div className="col-md-6 text-center center mb60">
                    <a href="mailto:hello@palaciodememoria.com">
                      <img
                        className="ml20"
                        width="90"
                        src="/images/events-icon2.png"
                        alt=""
                      />
                      <p className="font-gtaM red text-uppercase mt50">RSVP</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="EventsGallery" className="pt-5 pb-5">
          <div className="container">
            <div className="row mt30">
              <div className="col-md-6">
                <img
                  className="w-100 h-100"
                  src={imageGallery1.file.url}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <img
                  className="w-100 h-100"
                  src={imageGallery2.file.url}
                  alt=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <img
                  className="w-100 h-100"
                  src={imageGallery3.file.url}
                  alt=""
                />
              </div>
              <div className="col-md-4">
                <img
                  className="w-100 h-100"
                  src={imageGallery4.file.url}
                  alt=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="action-btns mt-5 mb20 text-center">
                  <Link to="/about/gallery#events">
                    <button className="btn">View More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="PostSuggestionsSection" className="pb-5 pt-5 mb40 mt40">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="font-tri red text-center">
                  Events you might like
                </h1>
              </div>
              <div className="col-md-6 mt50 text-center">
                <a href={`/event-details/${eventsYouMightLikeLeft.slug}`}>
                  <img
                    className="w-100"
                    src={eventsYouMightLikeLeft.featuredImage.file.url}
                    alt=""
                  />
                  <p className="font-gtaM event-date text-uppercase mt40">
                    {eventsYouMightLikeLeft.eventDate}
                  </p>
                  <h3 className="red mt20">
                    {eventsYouMightLikeLeft.infoSectionTitle}
                  </h3>
                </a>
              </div>
              <div className="col-md-6 mt50 text-center">
                <a href={`/event-details/${eventsYouMightLikeRight.slug}`}>
                  <img
                    className="w-100"
                    src={eventsYouMightLikeRight.featuredImage.file.url}
                    alt=""
                  />
                  <p className="font-gtaM event-date text-uppercase mt40">
                    {eventsYouMightLikeRight.eventDate}
                  </p>
                  <h3 className="red mt20">
                    {eventsYouMightLikeRight.infoSectionTitle}
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default EventsSpecificPage;

export const pageQuery = graphql`
  query EventsSpecificPageQuery($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulEventsSpecificDetails(slug: { eq: $slug }) {
      slug
      featuredImage {
        file {
          url
        }
      }
      infoSectionCategory
      infoSectionTitle
      blurb
      infoSectionPlace {
        infoSectionPlace
      }
      eventSpecificQuote {
        eventSpecificQuote
      }
      paragraphTitle1
      paragraphLeftColumn {
        paragraphLeftColumn
      }
      paragraphRightColumn {
        paragraphRightColumn
      }
      imageGallery1 {
        file {
          url
        }
      }
      imageGallery2 {
        file {
          url
        }
      }
      imageGallery3 {
        file {
          url
        }
      }
      imageGallery4 {
        file {
          url
        }
      }
      eventsYouMightLikeLeft {
        eventDate(formatString: "MMMM DD, YYYY")
        infoSectionTitle
        slug
        featuredImage {
          file {
            url
          }
        }
      }
      eventsYouMightLikeRight {
        eventDate(formatString: "MMMM DD, YYYY")
        infoSectionTitle
        slug
        featuredImage {
          file {
            url
          }
        }
      }
      guestSpeakers {
        guestSpeakerProfilePhoto {
          file {
            url
          }
        }
        speakerName
        guestSpeakerCompany {
          guestSpeakerCompany
        }
        guestSpeakerPosition
      }
      eventDate(formatString: "MMMM DD, YYYY / h:mm A")
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
